<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <FormItem>
          <Select v-model="queryForm.status" placeholder="状态">
            <Option value="">不限</Option>
            <Option value="CANCEL">已取消</Option>
            <Option value="FROZEN">冻结中</Option>
            <Option value="DONE">已完成</Option>
          </Select>
        </FormItem>
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="createdAtRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
      <FormItem>
        <Button type="info" @click="exportData()">
          <Icon type="ios-download-outline"></Icon>
          导出提现数据
        </Button>
      </FormItem>
    </Form>
    <Table stripe :data="items" :columns="tableColumns" ref="table" style="width: 100%" :loading="tableLoading" />
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryWithdraw, auditWithdraw, cancelWithdraw } from "@/api"
import { centiToOne, badgeWithdrawStatus, humanWithdrawStatus, toHumanDate } from "@/util"
import "@/components/tableUtil"

export default {
  name: "BillWithdrawQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "交易流水", link: this.$route.path }],
      items: [],
      itemListCount: 0,
      page: 1,
      createdAtRange: ["", ""],
      queryForm: { status: "" },
      tableLoading: false,
      exportDate: [],
      tableColumns: [
        { title: "流水号", key: "withdrawNo", fixed: "left", width: 180, maxWidth: 180 },
        { title: "银行流水号", key: "bankSerialNo", fixed: "left", width: 200, maxWidth: 200 },
        {
          title: "申请人",
          key: "nickname",
          render(h, p) {
            return h("div", p.row.user.nickname)
          },
          width: 100,
        },
        {
          title: "金额",
          key: "amount",
          render(h, p) {
            return h("div", centiToOne(p.row.amount))
          },
          width: 70,
          maxWidth: 70,
        },
        {
          title: "状态",
          key: "status",
          render(h, p) {
            return h("div", [badgeWithdrawStatus(h, p.row.status), humanWithdrawStatus(p.row.status)])
          },
          width: 70,
          maxWidth: 70,
        },
        { title: "银行名称", key: "bankName", width: 160, maxWidth: 160 },
        { title: "账户名称", key: "accountName", width: 200, maxWidth: 200 },
        { title: "账户", key: "accountNumber", width: 200, maxWidth: 200 },
        { title: "备注", key: "comment", width: 200, maxWidth: 200 },
        {
          title: "操作",
          width: 100,
          maxWidth: 300,
          key: "operation",
          render: (h, p) => {
            let btnDisable = false
            if (p.row.status === "DONE" || p.row.status === "CANCEL") {
              btnDisable = true
            }
            return h("ButtonGroup", [
              h("Button", {
                props: { type: "primary", disabled: btnDisable },
                on: {
                  click: () => {
                    this.audit(p.row)
                  },
                },
              }, "通过"),
              h("Button", {
                props: { disabled: btnDisable },
                on: {
                  click: () => {
                    this.cancelWithdraw(p.row)
                  },
                },
              }, "撤销"),
            ])
          },
        },
        {
          title: "创建时间",
          key: "createdAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.createdAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "取消时间",
          key: "canceledAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.canceledAt, defText: "-" },
            }),
          width: 100,
          maxWidth: 100,
        },
        {
          title: "结束时间",
          key: "finishedAt",
          render: (h, p) =>
            h("DateHolder", {
              props: { time: p.row.finishedAt, defText: "-" },
            }),
          width: 110,
          maxWidth: 110,
        },
      ],
    }
  },
  computed: {},
  watch: {
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
    async page() {
      await this.reload()
    },
    async createdAtRange() {
      await this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.tableLoading = true
        const q = this.queryForm
        let startTime = null,
          endTime = null
        const status = q.status === "" ? null : q.status
        if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
          startTime = this.createdAtRange[0].getTime()
          endTime = this.createdAtRange[1].getTime()
        }
        const { items, count } = await queryWithdraw(this.page, startTime, endTime, status)
        this.items = items
        this.itemListCount = count
      } catch (e) {
        this.$Message.error(e.message)
      } finally {
        this.tableLoading = false
      }
    },
    exportData() {
      const q = this.queryForm
      const status = q.status === "" ? null : q.status
      let startTime = ""
      let endTime = ""
      if (this.createdAtRange && this.createdAtRange.length == 2 && this.createdAtRange[0] instanceof Date) {
        startTime = this.createdAtRange[0].getTime()
        endTime = this.createdAtRange[1].getTime()
      }
      let url = "/backstage/export/export?exportType=billExport"
      if (startTime != null && startTime != "") {
        url += "&startTime=" + startTime
      }
      if (endTime != null && endTime != "") {
        url += "&endTime=" + endTime
      }
      if (status != null && status != "") {
        url += "&status=" + status
      }
      const target = document.createElement("a")
      target.href = url
      target.target = "_blank"
      target.click()
    },
    audit(row) {
      this.auditConfirm(row)
    },
    auditConfirm(row) {
      this.$Modal.confirm({
        title: "通过提现确认",
        content: "<p>确认通过本次提现吗？</p>",
        onOk: async () => {
          await auditWithdraw(row.withdrawNo)
          await this.reload()
        },
        onCancel: () => {
        },
      })
    },
    cancelWithdraw(row) {
      this.revokeConfirm(row)
    },
    revokeConfirm(row) {
      this.$Modal.confirm({
        title: "撤销提现确认",
        content: "<p>确认撤销本次提现吗？</p>",
        onOk: async () => {
          await cancelWithdraw(row.withdrawNo)
          await this.reload()
        },
        onCancel: () => {
        },
      })
    },
  },
}
</script>
